
.bg-primary, #footer {
  background-color: lighten($primary, 57.5%) !important;
}

.bg-secondary {
  background-color: $primary !important;
}

#header-logo {
  &.lang-de {
    background-image: url('../images/logo-gvb.png');
  }
  &.lang-fr {
    background-image: url('../images/logo-gvb-fr.png');
  }
}

#header-title {
  color: $black;
}

#locale-switcher {
  a {
    background-color: lighten($primary, 10%);
  }
  a.active {
    background-color: $primary;
  }
}