@font-face {
  font-family: 'Frutiger Light';
  src: url('../fonts/Frutiger\ LT/Frutiger LT W04 Light.woff2');
  src: url('../fonts/Frutiger\ LT/Frutiger LT W04 Light.ttf');
}

@font-face {
  font-family: 'Frutiger Bold';
  src: url('../fonts/Frutiger\ LT/Frutiger LT W04 Bold.woff2');
  src: url('../fonts/Frutiger\ LT/Frutiger LT W04 Bold.ttf');
}

// breakpoint dependent stuff
// xs: 0px (default)
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px

:root {
  --progress: #A1EA2A;
  --color-new_construction: #{$orange};
  --color-existing_construction: #{$purple};
  --color-proof: #{$red};
  --disabled-grey: #{$disabled-grey};
}

// IE11 Quirks
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

  /* IE10+ CSS styles go here */
  .select {
    position: relative;

    .indicator svg {
      max-width: 22px;
      height: 22px;
      float: right;
      position: relative;
      top: -18px;
      right: 5px;
    }

    .listItem {
      background-color: white;
    }
  }

  .usage-selection-option label {
    width: 161px;
    height: 134px;

    .option-label {
      z-index: 1000;
      // position: relative;
      // top: 0px;
      // padding-top: 40px;
    }

    .usage-icon {
      cursor: default;
    }

    .selectedItem {
      padding-left: 20px;
    }
  }
}



// END IE11


$footer-height: 69px;

.pointer {
  cursor: pointer;
}

html,
body {
  height: 100%;
  font-weight: 200;
  font-style: normal;
  font-size: 16px;
  color: #4B4B4B;
  text-align: left;
  line-height: 20px;
  color: $black;
  z-index: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Frutiger Light';

  @media (max-width: map-get($grid-breakpoints, "sm")) {
    word-break: break-word;
    hyphens: auto;
  }

  color: $primary;

}

h1 {
  font-size: 2.2rem;
}

h2 {
  padding-top: 20px;
  font-size: 1.6rem;
}

h3 {
  font-family: 'Frutiger Bold';
  color: $black;
  font-size: 1.1rem;
}

// menu,
// ol,
// ul {
//   padding: 0;
//   list-style-type: none;
// }
dd {
  margin: 0;
}

abbr[title] {
  text-decoration: none;
}

strong {
  font-family: 'Frutiger Bold';
}

// Layout
#top_nav {
  padding-bottom: 0px;
  background-color: $secondary;
  min-height: 10px;

  a {
    font-size: 1rem;
  }

  .col-spacer {
    width: 20px;
  }

  .navbar-item {
    padding: 0;
    padding-left: 25px;
    padding-right: 25px;

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    .navbar-item-content {
      padding: 0;
      height: 100%;
      border-bottom: 0.1px solid #6b6b6b;
      margin-left: 25px;

      @include media-breakpoint-up(md) {
        border-bottom: none;
        margin-left: 0px;
      }
    }

    &.black {
      padding: 0;
      margin-left: 0;
    }
  }

  .black {
    background-color: black;

    @include media-breakpoint-up(md) {
      background-color: inherit;
    }

    .navbar-item-content {
      border: none;
    }
  }

  .content {
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

#btt {
  width: 50;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1080;
}

// #5590: select dropdown has fixed z-index = 2. so default has index 0...
nav.navbar-expand-md.sticky-top {
  z-index: 1;
}

#header-logo {
  &.lang-de {
    background-image: url('../images/logo-gvb-white.png');
  }

  &.lang-fr {
    background-image: url('../images/logo-gvb-white-fr.png');
  }

  background-size: auto 73px;
  background-position: left 8px;
  background-repeat: no-repeat;
  display: inline-block;
  height: 180px;

  @include media-breakpoint-down(md) {
    background-size: auto 52px;
    background-position: left 12px;
    background-repeat: no-repeat;
    display: inline-block;
    height: 64px;
  }
}

#header-title {
  color: white;
  font-family: 'Frutiger Light'
}

#locale-switcher {
  float: right;
  padding-right: 0px;

  a {
    background-color: mix($primary, white, 80%);
    color: $primary-text;
  }

  a.active {
    background-color: $primary;
  }
}

.help {
  margin-top: 15px;
  font-size: 14px;
  color: $gray;
}

.help-heureka {
  margin-left: 10px;
}

/// BASIC LAYOUT
.container {
  max-width: 1440px;
}

.content {
  max-width: 1110px;
  border-width: 0px;
  margin-left: 15px;
  margin-right: 15px;
  display: block;
  position: relative;

  @include media-breakpoint-up(sm) {
    margin-left: 25px;
    margin-right: 25px;
  }

  @include media-breakpoint-up(xl) {
    margin-left: auto;
    margin-right: auto;
  }
}

.min-100 {
  min-height: 100% !important;
}

.head {
  // background-image: url(../images/top_white.png);
  background-image: url(../images/top.svg);
  // background-position: bottom;
  background-position: center bottom -1px;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 160px;
  padding-top: 30px;
}

#main-container {
  // min-height:100%;
  padding-bottom: $footer-height * 2;
}

.main {
  background-color: $white;
  margin-bottom: 120px;

  // the footer may cover content
  // will cover content only from md upwards
  @include media-breakpoint-down(sm) {
    margin-bottom: 0px;
  }

  .margin-left {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  h1 {
    margin-bottom: 4rem;
  }
}

#footer {
  /** btn (z:2) would be visible on footer if z-index was only 1 */
  z-index: 1030;
  /** should cover sidebar which has 1020 */
  position: fixed;
  bottom: 0px;
  height: $footer-height;
  padding-top: 20px;
  background-color: $primary;
  right: 0px;
  left: 0px;

  .btn {
    background-color: lighten($primary, 10%);
  }
}



.step-nav {
  font-family: 'Frutiger Bold';
  margin-bottom: 15px;
  color: $disabled-grey;

  @include media-breakpoint-down(sm) {
    margin-bottom: 0px;
  }

  .link {
    color: $disabled-grey;
    position: relative;
    bottom: 11px;
  }

  &.active {
    span.link {
      color: $black;
    }

    .inside-circle {
      color: $primary;
    }
  }

  svg {
    transform: rotate(-90deg);
    stroke-dasharray: 95px;
    /* (2PI * r) */
    position: absolute;
  }

  .circle-wrap {
    display: inline-block;
    margin: 0;
    width: 36px;
    height: 36px;
    margin-right: 15px;
  }

  .circle-wrap .inside-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    margin-top: 3px;
    margin-left: 3px;
    position: absolute;
    z-index: 1;
  }

  .circle-wrap .inside-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    margin-top: 3px;
    margin-left: 3px;
    position: absolute;
    z-index: 1;
    padding-top: 12px;
    font-weight: bold;
  }

  .inside-circle>svg {
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
  }
}

.home-nav {
  font-family: 'Frutiger Bold';
  margin-bottom: 15px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 0px;
  }

  .link {
    color: $disabled-grey;
    position: relative;
    bottom: 11px;
  }

  &.active {
    .link {
      color: $black;
    }

    .inside-circle {
      color: $primary;
    }
  }

  svg {
    transform: rotate(-90deg);
    stroke-dasharray: 95px;
    /* (2PI * r) */
    position: absolute;
  }

  .circle-wrap {
    display: inline-block;
    margin: 0;
    width: 36px;
    height: 36px;
    margin-right: 0;
  }

  .circle-wrap .inside-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    margin-top: 3px;
    margin-left: 3px;
    position: absolute;
    z-index: 1;
  }

  .circle-wrap .inside-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    margin-top: 3px;
    margin-left: 3px;
    position: absolute;
    z-index: 1;
    padding-top: 13px;
    font-weight: bold;
  }
}


span.question,
form label {
  font-family: 'Frutiger Bold';
}

.bool-question {

  .bool-checked,
  .bool-unchecked {
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    height: 25px;
    width: 25px;
  }

  .bool-checked {
    background-image: url(../images/eheureka-circle-dot.svg);
  }

  .bool-unchecked {
    background-image: url(../images/eheureka-circle.svg);
  }

  label {
    cursor: pointer;
    position: relative;
    top: 2px;
    vertical-align: bottom;
  }

  .control {
    cursor: pointer;
  }
}

.buttonbar {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;

  a,
  input,
  button {
    margin-left: 25px;
  }

  .back-button {
    @extend .btn;
    @extend .btn-sized;
    @extend .btn-grey;
    order: 1;
  }

  .continue-button {
    @include media-breakpoint-up(lg) {
      // float: right;
    }

    order: 2;
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-sized;
  }

  .alert-button {
    @extend .btn;
    @extend .btn-primary;
    width: auto;
    border: solid $white 1px;

    &:hover {
      border: solid darken($white, 10%) 1px;
    }
  }
}

.welcome-section {
  h2 {
    color: $black;
    font-size: 16px;
    font-family: 'Frutiger Bold';
  }

  &.new-project {
    .new-project-buttons {
      // display: flex;
      // flex-wrap: wrap;
      // gap: 8px;
      margin-top: 12px;

      .new-project-button {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .btn-construction {
          flex-grow: 1;
          display: flex;
          flex-direction: column-reverse;
          row-gap: .75rem;
          // margin-right: 0.75rem;
          // text-align: left;
          padding: 0.75rem;

          &:hover {
            text-decoration: none;
          }

          .btn-description {
            font-size: .9rem;
            margin-bottom: auto;
          }

          .btn-divider {
            border-bottom: 1px solid white;
          }

          .btn-title {
            display: flex;
            align-items: center;
            font-size: 1.35rem;
            line-height: 1;
          }
        }

        .pdf-preview {
          display: flex;
          align-items: center;
          margin-top: 6px;
          margin-right: 12px;
          font-size: .9rem;

          &:hover {
            text-decoration: none;
          }

          .pdf-preview-text {
            margin-bottom: 0;
          }

          .pdf-preview-icon {
            padding: 12px;
            padding-left: 0;

            >svg {
              width: 2rem;
              height: 2rem;
            }
          }
        }
      }
    }
  }

  &.existing-project {
    .open-project-form {
      display: flex;
      align-items: flex-end;

      .open-project-button {
        @extend .btn;
        @extend .btn-primary;
        @extend .btn-sized;
      }
    }
  }
}

.welcome-text {
  a {
    text-decoration: underline;
  }
}


// TODO: for the layout they should rather be classes than ids
// (since they may exist multiple times on the same page)

.enter-button {
  @extend .btn;
  @extend .btn-primary;
}

.variable-margins {
  margin: 50px 0px;

  @include media-breakpoint-down(md) {
    margin: 25px 0px;
  }
}

.steps-nav,
.steps-title {
  @extend .variable-margins;
  margin-top: 0;
}

/// Current Project
#current_project {
  @extend .variable-margins;

  h3 {
    border-top: 1px solid $vertical-lines;
    color: $black;
    border-bottom: 1px solid $vertical-lines;
    padding: 10px 0px 10px 0px;
  }
}

.clipboard {
  pre {
    color: #666;
  }
}


/// Home
.home-user-alert {
  margin-top: -2em;
  margin-bottom: 2em;
}


/// Questionnaires (Preconditions & Questions)
section.questionnaire-item {
  border-top: #eee solid 1px;
  padding-top: 30px;
  margin-top: 30px;

  &:first-child {
    border-top: none;
    padding-top: 0px;
    margin-top: 0px;
  }

  &:last-child {
    border-bottom: #eee solid 1px;
    ;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  &.alert-item {
    padding-top: 0px;
    margin-top: 0px;
    border-top: none;
  }

  .question-help {
    @extend .help;

    p {
      margin-bottom: 0;
    }
  }

  .answers {
    margin-top: 15px;
  }
}


/// Summary
#summary {
  .summary-section-title {
    position: relative;

    a {
      margin-left: 30px !important;
      transition: font-size .1s ease-in-out;
      position: absolute;
      bottom: 0px;

      &:hover {
        font-size: 130%;
      }
    }
  }

  .summary-item {
    .title {
      font-family: 'Frutiger Bold';

      p {
        margin: 0;
      }
    }

    .value {
      margin-top: 5px;
      margin-bottom: 20px;
    }

    td.number {
      width: 10mm;
      padding-left: 0;
    }
  }
}

/// Utilities

.warning-panel {
  padding: 15px;
  background-color: $primary;
  color: $white;

  * {
    color: $white;
  }

  .title {
    font-weight: bold;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: white;
    }
  }
}

.info-panel {
  @extend .question-help;
  background: none;

  * {
    color: $primary
  }

  .title {
    font-weight: bold;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: $primary;
    }
  }
}

.usage-help {
  @extend .question-help;

  * {
    color: $primary;
  }
}

.usage-icon {
  width: 70%;
  margin: -12%;
}

.text-gray {
  color: $gray;
}

.text-black {
  color: $black;
}

.overlay {
  background-color: rgba($primary, 0.5);
  position: fixed;
  /* Sit on top of the page content */
  display: block;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(0,0,0,0.3); /* Black background with opacity */
  z-index: 1090;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

.dialog {
  width: 95%;
  min-width: 300px;
  max-width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 600px;
  }
}

.text-green {
  color: #A1EA2A;
}

/////**** FROM BACKEND

.logo-img {
  background-image: image-url("../images/logo-gvb-white-1line.png");
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  display: inline-block;
  margin: 0px;
  width: 260px;
  height: 64px;

  img {
    opacity: 0.01;
  }
}

.gvb-title {
  line-height: 24px;
  font-size: 24px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.gvb-subtitle {
  line-height: 16px;
  font-size: 16px;
}

.logo-wrapper {
  max-width: 180px;
}

.title-wrapper {
  padding: 0px 43px 0px 43px;
  border-left: solid $white 1px;
}

// Navigation
button.navbar-toggler {
  padding: 6px 0px;
  border: 0px;
}

/* remove navbar button stying */
.navbar-toggler {
  background: none;
  border: none;

  /* remove the blue outline when active or focused */
  &:active,
  &:focus {
    outline: 0;
  }

  /* basic styles for each icon bar */
  .icon-bar {
    display: block;
    width: 30px;
    height: 2px;
    border-radius: 1px;
    margin: 6px 0 6px 0;
    transition: all 0.2s;

    /* custom .navbar-light .icon-bar background */
    .navbar-light & {
      background: #ffffff;
    }

    /* .navbar open top .icon-bar rotated down 45° */
    &.top-bar {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
    }

    /* .navbar open middle .icon-bar invisible */
    &.middle-bar {
      opacity: 0;
      filter: alpha(opacity=0);
    }

    /* .navbar open bottom .icon-bar rotated up 45° */
    &.bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
    }
  }

  /* styles for when .navbar is closed */
  &.collapsed {
    .icon-bar {

      /* .navbar closed top .icon-bar no rotation - straight */
      &.top-bar {
        transform: rotate(0);
      }

      /* .navbar open middle .icon-bar visible */
      &.middle-bar {
        opacity: 1;
        filter: alpha(opacity=100);
      }

      /* .navbar open bottom .icon-bar no rotation - straight */
      &.bottom-bar {
        transform: rotate(0);
      }

    }

  }

}

// Flashes
.alert-notice {
  @extend .alert-info;
}

.alert-error {
  @extend .alert-danger;
}

// Forms
.form-actions {
  margin-top: 3rem;
}

.form-inputs .links {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 3rem;

  label {
    margin-bottom: 0.3rem;
  }
}

form legend {
  font-family: 'Frutiger Bold';
  font-size: 16px;
  margin-bottom: 0;
}

.fieldset.fieldset-collapsible {
  &:last-child {
    margin-bottom: 48px;
  }

  .fields {
    padding-left: 28px;
    margin-top: 36px;
  }

  .btn-fieldset-collapse {
    @extend .btn-block;
    text-align: left;
    border-bottom: 1px solid lightgrey;

    &:focus {
      box-shadow: none;
    }

    &:focus-visible {
      outline: 0;
      box-shadow: 0 0.1rem 0 0 rgba($primary, 0.25);
      border-bottom: 1px solid $primary;
    }

    .fa-chevron-right {
      color: $primary;
      transition: transform .35s ease;
      margin: 0 3px 0 -3px;
    }

    &[aria-expanded=true] {
      .fa-chevron-right {
        transform: rotate(90deg);
      }
    }
  }
}

.custom-file-label {
  //   /* Makes the file input as high as the appended button, but introduces a small gap under "Browse" button. */
  //   height: 100%;
  //   background-color: $secondary;
  //   overflow-x: hidden;
  font-size: inherit !important;
}

.custom-control-label {
  &.collection_radio_buttons {
    font-family: 'Frutiger Light';
  }
}

.sortable-row {
  cursor: move;
}

// Index Tables

table td.actions {
  text-align: right;
}

// Sortable tables
.sortable-table {
  display: table;
  width: 100%;
  border-collapse: collapse;

  .handle {
    cursor: move;
  }
}

.sortable-table-row {
  background-color: lighten($primary, 60%);

  &.confset-entry {
    background-color: lighten($success, 45%);
  }

  display: table-row;
  margin-bottom: 12px;
  border: 1px;
}

.sortable-table-cell {
  border-bottom: 1px solid lighten($dark, 60%);
  border-top: 1px solid lighten($dark, 60%);

  &.askable-code,
  &.rulable-code,
  &.code {
    width: 200px;
  }

  &:last-child {}

  display: table-cell;
  vertical-align: middle;
  padding-left: 12px;

  &:first-child {
    border-left: 1px solid lighten($dark, 60%);
    padding-left: 0px;
    width: 33%;
  }

  &.icon-cell {
    width: 38px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-right: 1px solid lighten($dark, 60%);
  }

  .form-group {
    margin-bottom: 0px;
  }
}

// pagy
li.page-item a.page-link {
  @extend .btn-outline-primary;
}

// wizard
.wizard-ruleset-item {
  >p:last-of-type {
    margin-bottom: 0;
  }

  a.btn {
    margin-top: .5rem;
  }
}


// project kinds
// project kinds
.btn-new_construction {
  color: mix($new_construction-color, white, 5%);
  background-color: $new_construction-color;
  border-color: $new_construction-color;

  &:hover {
    color: mix($new_construction-color, white, 5%);
  }
}

.btn-existing_construction {
  color: mix($existing_construction-color, white, 5%);
  background-color: $existing_construction-color;
  border-color: $existing_construction-color;

  &:hover {
    color: mix($existing_construction-color, white, 5%);
  }
}

.btn-proof {
  color: mix($proof-color, white, 5%);
  background-color: $proof-color;
  border-color: $proof-color;

  &:hover {
    color: mix($proof-color, white, 5%);
  }
}


.text-new_construction {
  color: $new_construction-color;
  --iconFillColor: #{$new_construction-color};

  &:hover {
    color: $new_construction-color;
  }
}

.text-existing_construction {
  color: $existing_construction-color;
  --iconFillColor: #{$existing_construction-color};

  &:hover {
    color: $existing_construction-color;
  }
}

.text-proof {
  color: $proof-color;
  --iconFillColor: #{$proof-color};

  &:hover {
    color: $proof-color;
  }
}

.text-underline {
  text-decoration: underline;
}

.selectContainer input[type=text] {

  &:focus,
  &:focus-visible {
    box-shadow: 0 0.1rem 0 0 rgba(75, 75, 75, 0.25);
  }
}

.proof .select.select-overflow {
  .listContainer {
    --itemPadding: 10px;
    width: calc(210% + var(--itemPadding)) !important;
  }

  &.select-overflow-left .listContainer {
    right: calc(-1 * var(--itemPadding));
    left: unset;
  }

  &.select-overflow-right .listContainer {
    left: calc(-1 * var(--itemPadding));
    right: unset;
  }
}

.btn:disabled {
  opacity: .3;
}

// @each $name,
// $color in $theme-colors {
//   @each $weight in $color-weights {
//     .bg-#{$name}-800 {
//       @debug $color;
//       background-color: mix($color, white, 80%);
//     }

//     .bg-#{$name}-600 {
//       background-color: mix($color, white, 60%);
//     }

//     .bg-#{$name}-400 {
//       background-color: mix($color, white, 40%);
//     }

//     .bg-#{$name}-200 {
//       background-color: mix($color, white, 20%);
//     }
//   }
// }

.bg-primary {
  background-color: $primary-bg !important;
}

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
}

.sidebar-collapse.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

.sidebar {
  padding-right: 15px;
  padding-left: 15px;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    max-width: 30%;
  }

  .steps-nav {
    position: relative;

    .btn-collapse {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }

      position: absolute;
      right: -2rem;
      top: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    .sidebar-collapse {
      display: initial;
    }
  }

  &.show {
    .collapse {
      display: initial;
    }
  }
}