h1 {
  font-size: 1.5rem;
  @include media-breakpoint-up(sm) {
    font-size: 2.4rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2.67rem;
  }
  // @include media-breakpoint-down(lg) {
  // }
}