//
// Color system
//

// $font-family-sans-serif: "Frutiger Light", "Helvetica Neue", Arial, sans-serif !default;
// $font-size-base: 1rem; //1.0714285714285714rem !default; // 15px

// $black: #4B4B4B;
// $gray: #b1b1b1; // Taken from prototype help text on "Einige Fragen vorab"
// $disabled-grey: #ccc;
// $white: #FFF;

// $purple: #5e2b68;
$primary: $black;
$primary-text: white;
$primary-bg: lightgrey;
// $secondary: rgb(75, 75, 75) !default;

// $success: $secondary !default;
// $info: $secondary !default;
// $warning: $secondary !default;
// $danger: $primary !default;

// $orange: rgba(218, 139, 49, 1) !default;
/* #b9792f !default; */

// $vertical-lines: #eee;

// $headings-font-weight: 350 !default;

// $border-radius: 0 !default;
// $border-radius-lg: 0 !default;
// $border-radius-sm: 0 !default;

// $h3-font-size: $font-size-base * 1.1 !default;

// $navbar-light-color:                rgba($white, .5) !default;