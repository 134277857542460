.form-control,
.form-control:focus {
  border-top: none;
  border-left: none;
  border-right: none;
}
.form-control:focus {
  box-shadow: 0 0.1rem 0 0 rgba($primary, 0.25);
}

// .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
//     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
// }

.radio_buttons {
  .custom-control-label {
    line-height: 1.75rem;
  }

  .custom-control-label::before {
    border: none;
    background: url(../images/eheureka-circle.svg);
    border-radius: 50%;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background: url(../images/eheureka-circle-dot.svg);
  }
}

.check_boxes {
  .custom-control-label {
    line-height: 1.75rem;
  }

  .custom-control-label::before {
    border: none;
    background: url(../images/eheureka-square.svg);
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background: url(../images/eheureka-square-times.svg);
  }
}

.btn
{
  font-family: 'Frutiger Bold';
}
.btn-sized
{
  @include media-breakpoint-up(lg) {
    min-width: 180px;
    padding: 10px 20px;
  }
}
.btn-primary {
  color: white;
}

// generate custom buttons, if needed
// ($background, $border, $hover-background: darken($background, 7.5%),
//       $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
.btn-grey {
  @include button-variant(#f0f0f0, #f0f0f0);
  color: #a3a3a3;
  &:hover {
    color: #a3a3a3;
  }
}

select.form-control {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1.07143rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  // background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 5'%3e%3cpath fill='%23343a40' d='M10 0L5 5 0 0h2l3 3 3-3z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/10px 10px;
  background-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  // border: 1px solid #ced4da;
  // border-top-color: rgb(206, 212, 218);
  // border-top-style: solid;
  // border-top-width: 1px;
  // border-right-color: rgb(206, 212, 218);
  // border-right-style: solid;
  // border-right-width: 1px;
  // border-left-color: rgb(206, 212, 218);
  // border-left-style: solid;
  // border-left-width: 1px;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.selectContainer {
  .listContainer {
    .listItem {
      border-bottom: 1px solid #efefef;
    }
  }
}